exports.components = {
  "component---src-pages-404-cy-tsx": () => import("./../../../src/pages/404.cy.tsx" /* webpackChunkName: "component---src-pages-404-cy-tsx" */),
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-index-cy-tsx": () => import("./../../../src/pages/index.cy.tsx" /* webpackChunkName: "component---src-pages-index-cy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-schools-cy-tsx": () => import("./../../../src/pages/schools.cy.tsx" /* webpackChunkName: "component---src-pages-schools-cy-tsx" */),
  "component---src-pages-schools-en-tsx": () => import("./../../../src/pages/schools.en.tsx" /* webpackChunkName: "component---src-pages-schools-en-tsx" */),
  "component---src-pages-students-cy-tsx": () => import("./../../../src/pages/students.cy.tsx" /* webpackChunkName: "component---src-pages-students-cy-tsx" */),
  "component---src-pages-students-en-tsx": () => import("./../../../src/pages/students.en.tsx" /* webpackChunkName: "component---src-pages-students-en-tsx" */)
}

